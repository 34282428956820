import * as THREE from 'three'

import Loader from './Utils/Loader.js'
import EventEmitter from './Utils/EventEmitter.js'

// Matcaps
import matcapBeigeSource from '../models/matcaps/beige.png'
import matcapBlackSource from '../models/matcaps/black.png'
import matcapOrangeSource from '../models/matcaps/orange.png'
import matcapRedSource from '../models/matcaps/red.png'
import matcapWhiteSource from '../models/matcaps/white.png'
import matcapGreenSource from '../models/matcaps/green.png'
import matcapLineSource from '../models/matcaps/line.png'
import matcapBrownSource from '../models/matcaps/brown.png'
import matcapMarbleSource from '../models/matcaps/marble.png'
import matcapDarkEmeraldSource from '../models/matcaps/darkEmerald.png'
import matcapDarkMetalSource from '../models/matcaps/darkMetal.png'
import matcapBlueGlassSource from '../models/matcaps/blueGlass.png'
import matcapCharcoalSource from '../models/matcaps/charcoal.png'
import matcapSkySource from '../models/matcaps/sky.png'
import matcapBronzSource from '../models/matcaps/bronz.png'
import matcapExoticSource from '../models/matcaps/exotic.png'
import matcapBwSource from '../models/matcaps/bw.png'
import matcapCoralSource from '../models/matcaps/coral.png'
import matcapTransparentLandSource from '../models/matcaps/transparentLand.png'
import matcapVolcanoSource from '../models/matcaps/volcano.png'
import matcapElevatorSource from '../models/matcaps/elevator.png'
import matcapDivoSource from '../models/matcaps/divo.png'
import matcapEarthSource from '../models/matcaps/earth.png'
import matcapGreenBulbSource from '../models/matcaps/greenBulb.png'
import matcapPanaceaSource from '../models/matcaps/panacea.png'
import matcapGraySource from '../models/matcaps/gray.png'
import matcapEmeraldGreenSource from '../models/matcaps/emeraldGreen.png'
import matcapPurpleSource from '../models/matcaps/purple.png'
import matcapBlueSource from '../models/matcaps/blue.png'
import matcapYellowSource from '../models/matcaps/yellow.png'
import matcapMetalSource from '../models/matcaps/metal.png'
// import matcapGoldSource from '../models/matcaps/gold.png'

// Intro
import introStaticBaseSource from '../models/intro/static/base.glb'
import introStaticCollisionSource from '../models/intro/static/collision.glb'
import introStaticFloorShadowSource from '../models/intro/static/floorShadow.png'

import introInstructionsLabelsSource from '../models/intro/instructions/labels.glb'
import introInstructionsArrowsSource from '../models/intro/instructions/arrows.png'
import introInstructionsControlsSource from '../models/intro/instructions/controls.png'
import introInstructionsOtherSource from '../models/intro/instructions/other.png'

import introArrowKeyBaseSource from '../models/intro/arrowKey/base.glb'
import introArrowKeyCollisionSource from '../models/intro/arrowKey/collision.glb'

import introBBaseSource from '../models/intro/b/base.glb'
import introBCollisionSource from '../models/intro/b/collision.glb'

import introRBaseSource from '../models/intro/r/base.glb'
import introRCollisionSource from '../models/intro/r/collision.glb'
import introRTextureSource from '../models/intro/static/introRTexture.png'

import introUBaseSource from '../models/intro/u/base.glb'
import introUCollisionSource from '../models/intro/u/collision.glb'

import introNBaseSource from '../models/intro/n/base.glb'
import introNCollisionSource from '../models/intro/n/collision.glb'

import introOBaseSource from '../models/intro/o/base.glb'
import introOCollisionSource from '../models/intro/o/collision.glb'

import introSBaseSource from '../models/intro/s/base.glb'
import introSCollisionSource from '../models/intro/s/collision.glb'

import introIBaseSource from '../models/intro/i/base.glb'
import introICollisionSource from '../models/intro/i/collision.glb'

import introMBaseSource from '../models/intro/m/base.glb'
import introMCollisionSource from '../models/intro/m/collision.glb'

import introCreativeBaseSource from '../models/intro/creative/base.glb'
import introCreativeCollisionSource from '../models/intro/creative/collision.glb'

import introDevBaseSource from '../models/intro/dev/base.glb'
import introDevCollisionSource from '../models/intro/dev/collision.glb'

// Crossroads
import crossroadsStaticFloorShadowSource from '../models/crossroads/static/floorShadow.png'
import crossroadsStaticBaseSource from '../models/crossroads/static/base.glb'
import crossroadsStaticCollisionSource from '../models/crossroads/static/collision.glb'

// Car default
import carDefaultChassisSource from '../models/car/default/chassis.glb'
import carDefaultWheelSource from '../models/car/default/wheel.glb'
import carDefaultBackLightsBrakeSource from '../models/car/default/backLightsBrake.glb'
import carDefaultBackLightsReverseSource from '../models/car/default/backLightsReverse.glb'
import carDefaultBackLightsBatterySource from '../models/car/default/backLightsBattery.glb'
import carDefaultAntenaSource from '../models/car/default/antena.glb'

// Car default 1
import car1DefaultChassisSource from '../models/car1/default/chassis.glb'
import car1DefaultWheelSource from '../models/car1/default/wheel.glb'
import car1DefaultBackLightsBrakeSource from '../models/car1/default/backLightsBrake.glb'
import car1DefaultBackLightsReverseSource from '../models/car1/default/backLightsReverse.glb'
import car1DefaultBackLightsBatterySource from '../models/car1/default/backLightsBattery.glb'
import car1DefaultAntenaSource from '../models/car1/default/antena.glb'

// Car default 2
import car2DefaultChassisSource from '../models/car2/default/chassis.glb'
import car2DefaultWheelSource from '../models/car2/default/wheel.glb'
import car2DefaultBackLightsBrakeSource from '../models/car2/default/backLightsBrake.glb'
import car2DefaultBackLightsReverseSource from '../models/car2/default/backLightsReverse.glb'
import car2DefaultBackLightsBatterySource from '../models/car2/default/backLightsBattery.glb'
import car2DefaultAntenaSource from '../models/car2/default/antena.glb'

// Car default 3
import car3DefaultChassisSource from '../models/car3/default/chassis.glb'
import car3DefaultWheelSource from '../models/car3/default/wheel.glb'
import car3DefaultBackLightsBrakeSource from '../models/car3/default/backLightsBrake.glb'
import car3DefaultBackLightsReverseSource from '../models/car3/default/backLightsReverse.glb'
import car3DefaultBackLightsBatterySource from '../models/car3/default/backLightsBattery.glb'
import car3DefaultAntenaSource from '../models/car3/default/antena.glb'

// Information
import informationStaticBaseSource from '../models/information/static/base.glb'
import informationStaticCollisionSource from '../models/information/static/collision.glb'
import informationStaticFloorShadowSource from '../models/information/static/floorShadow.png'

import informationContactTwitterLabelSource from '../models/information/static/contactTwitterLabel.png'
import informationContactGithubLabelSource from '../models/information/static/contactGithubLabel.png'
import informationContactLinkedinLabelSource from '../models/information/static/contactLinkedinLabel.png'
import informationContactMailLabelSource from '../models/information/static/contactMailLabel.png'

import informationActivitiesSource from '../models/information/static/activities.png'

// Playground
import playgroundStaticFloorShadowSource from '../models/playground/static/floorShadow.png'
import playgroundStaticBaseSource from '../models/playground/static/base.glb'
import playgroundStaticCollisionSource from '../models/playground/static/collision.glb'

// Brick
import brickBaseSource from '../models/brick/base.glb'
import brickCollisionSource from '../models/brick/collision.glb'

// Bowling ball
import bowlingBallBaseSource from '../models/bowlingBall/base.glb'
import bowlingBallCollisionSource from '../models/bowlingBall/collision.glb'

// Rocket
import rocketBaseSource from '../models/rocket/base.glb'
import rocketCollisionSource from '../models/rocket/collision.glb'

// Airdrop
import airdropBaseSource from '../models/airdrop/base.glb'
import airdropCollisionSource from '../models/airdrop/collision.glb'

// Battery
import batteryChargerBaseSource from '../models/rocket/base.glb'
import batteryChargerCollisionSource from '../models/rocket/collision.glb'

// Bowling pin
import bowlingPinBaseSource from '../models/bowlingPin/base.glb'
import bowlingPinCollisionSource from '../models/bowlingPin/collision.glb'

// Area
import areaKeyEnterSource from '../models/area/keyEnter.png'
import areaEnterSource from '../models/area/enter.png'
import areaOpenSource from '../models/area/open.png'
import areaResetSource from '../models/area/reset.png'
import areaQuestionMarkSource from '../models/area/questionMark.png'

// Tiles
import tilesABaseSource from '../models/tiles/a/base.glb'
import tilesACollisionSource from '../models/tiles/a/collision.glb'

import tilesBBaseSource from '../models/tiles/b/base.glb'
import tilesBCollisionSource from '../models/tiles/b/collision.glb'

import tilesCBaseSource from '../models/tiles/c/base.glb'
import tilesCCollisionSource from '../models/tiles/c/collision.glb'

import tilesDBaseSource from '../models/tiles/d/base.glb'
import tilesDCollisionSource from '../models/tiles/d/collision.glb'

import tilesEBaseSource from '../models/tiles/e/base.glb'
import tilesECollisionSource from '../models/tiles/e/collision.glb'

export default class Resources extends EventEmitter
{
    constructor()
    {
        super()

        this.loader = new Loader()
        this.items = {}

        this.loader.load([
            // Matcaps
            { name: 'matcapBeige', source: matcapBeigeSource, type: 'texture' },
            { name: 'matcapBlack', source: matcapBlackSource, type: 'texture' },
            { name: 'matcapOrange', source: matcapOrangeSource, type: 'texture' },
            { name: 'matcapRed', source: matcapRedSource, type: 'texture' },
            { name: 'matcapWhite', source: matcapWhiteSource, type: 'texture' },
            { name: 'matcapGreen', source: matcapGreenSource, type: 'texture' },
            { name: 'matcapLine', source: matcapLineSource, type: 'texture' },
            { name: 'matcapBrown', source: matcapBrownSource, type: 'texture' },
            { name: 'matcapMarble', source: matcapMarbleSource, type: 'texture' },
            { name: 'matcapDarkEmerald', source: matcapDarkEmeraldSource, type: 'texture' },
            { name: 'matcapDarkMetal', source: matcapDarkMetalSource, type: 'texture' },
            { name: 'matcapBlueGlass', source: matcapBlueGlassSource, type: 'texture' },
            { name: 'matcapCharcoal', source: matcapCharcoalSource, type: 'texture' },
            { name: 'matcapSky', source: matcapSkySource, type: 'texture' },
            { name: 'matcapBronz', source: matcapBronzSource, type: 'texture' },
            { name: 'matcapExotic', source: matcapExoticSource, type: 'texture' },
            { name: 'matcapBw', source: matcapBwSource, type: 'texture' },
            { name: 'matcapCoral', source: matcapCoralSource, type: 'texture' },
            { name: 'matcapTransparentLand', source: matcapTransparentLandSource, type: 'texture' },
            { name: 'matcapVolcano', source: matcapVolcanoSource, type: 'texture' },
            { name: 'matcapElevator', source: matcapElevatorSource, type: 'texture' },
            { name: 'matcapDivo', source: matcapDivoSource, type: 'texture' },
            { name: 'matcapEarth', source: matcapEarthSource, type: 'texture' },
            { name: 'matcapGreenBulb', source: matcapGreenBulbSource, type: 'texture' },
            { name: 'matcapPanacea', source: matcapPanaceaSource, type: 'texture' },
            { name: 'matcapGray', source: matcapGraySource, type: 'texture' },
            { name: 'matcapEmeraldGreen', source: matcapEmeraldGreenSource, type: 'texture' },
            { name: 'matcapPurple', source: matcapPurpleSource, type: 'texture' },
            { name: 'matcapBlue', source: matcapBlueSource, type: 'texture' },
            { name: 'matcapYellow', source: matcapYellowSource, type: 'texture' },
            { name: 'matcapMetal', source: matcapMetalSource, type: 'texture' },
            // { name: 'matcapGold', source: matcapGoldSource, type: 'texture' },

            // Intro
            { name: 'introStaticBase', source: introStaticBaseSource },
            { name: 'introStaticCollision', source: introStaticCollisionSource },
            { name: 'introStaticFloorShadow', source: introStaticFloorShadowSource, type: 'texture' },

            { name: 'introInstructionsLabels', source: introInstructionsLabelsSource },
            { name: 'introInstructionsArrows', source: introInstructionsArrowsSource, type: 'texture' },
            { name: 'introInstructionsControls', source: introInstructionsControlsSource, type: 'texture' },
            { name: 'introInstructionsOther', source: introInstructionsOtherSource, type: 'texture' },

            { name: 'introArrowKeyBase', source: introArrowKeyBaseSource },
            { name: 'introArrowKeyCollision', source: introArrowKeyCollisionSource },

            { name: 'introBBase', source: introBBaseSource },
            { name: 'introBCollision', source: introBCollisionSource },

            { name: 'introRBase', source: introRBaseSource },
            { name: 'introRCollision', source: introRCollisionSource },
            { name: 'introRTexture', source: introRTextureSource, type: 'texture' },

            { name: 'introUBase', source: introUBaseSource },
            { name: 'introUCollision', source: introUCollisionSource },

            { name: 'introNBase', source: introNBaseSource },
            { name: 'introNCollision', source: introNCollisionSource },

            { name: 'introOBase', source: introOBaseSource },
            { name: 'introOCollision', source: introOCollisionSource },

            { name: 'introSBase', source: introSBaseSource },
            { name: 'introSCollision', source: introSCollisionSource },

            { name: 'introIBase', source: introIBaseSource },
            { name: 'introICollision', source: introICollisionSource },

            { name: 'introMBase', source: introMBaseSource },
            { name: 'introMCollision', source: introMCollisionSource },

            { name: 'introCreativeBase', source: introCreativeBaseSource },
            { name: 'introCreativeCollision', source: introCreativeCollisionSource },

            { name: 'introDevBase', source: introDevBaseSource },
            { name: 'introDevCollision', source: introDevCollisionSource },

            // Intro
            { name: 'crossroadsStaticBase', source: crossroadsStaticBaseSource },
            { name: 'crossroadsStaticCollision', source: crossroadsStaticCollisionSource },
            { name: 'crossroadsStaticFloorShadow', source: crossroadsStaticFloorShadowSource, type: 'texture' },

            // Car default
            { name: 'carDefaultChassis', source: carDefaultChassisSource },
            { name: 'carDefaultWheel', source: carDefaultWheelSource },
            { name: 'carDefaultBackLightsBrake', source: carDefaultBackLightsBrakeSource },
            { name: 'carDefaultBackLightsReverse', source: carDefaultBackLightsReverseSource },
            { name: 'carDefaultBackLightsBattery', source: carDefaultBackLightsBatterySource },
            { name: 'carDefaultAntena', source: carDefaultAntenaSource },

            // Car1 default
            { name: 'car1DefaultChassis', source: car1DefaultChassisSource },
            { name: 'car1DefaultWheel', source: car1DefaultWheelSource },
            { name: 'car1DefaultBackLightsBrake', source: car1DefaultBackLightsBrakeSource },
            { name: 'car1DefaultBackLightsReverse', source: car1DefaultBackLightsReverseSource },
            { name: 'car1DefaultBackLightsBattery', source: car1DefaultBackLightsBatterySource },
            { name: 'car1DefaultAntena', source: car1DefaultAntenaSource },

            // Car2 default
            { name: 'car2DefaultChassis', source: car2DefaultChassisSource },
            { name: 'car2DefaultWheel', source: car2DefaultWheelSource },
            { name: 'car2DefaultBackLightsBrake', source: car2DefaultBackLightsBrakeSource },
            { name: 'car2DefaultBackLightsReverse', source: car2DefaultBackLightsReverseSource },
            { name: 'car2DefaultBackLightsBattery', source: car2DefaultBackLightsBatterySource },
            { name: 'car2DefaultAntena', source: car2DefaultAntenaSource },
            
            // Car3 default
            { name: 'car3DefaultChassis', source: car3DefaultChassisSource },
            { name: 'car3DefaultWheel', source: car3DefaultWheelSource },
            { name: 'car3DefaultBackLightsBrake', source: car3DefaultBackLightsBrakeSource },
            { name: 'car3DefaultBackLightsReverse', source: car3DefaultBackLightsReverseSource },
            { name: 'car3DefaultBackLightsBattery', source: car3DefaultBackLightsBatterySource },
            { name: 'car3DefaultAntena', source: car3DefaultAntenaSource },

            // Information
            { name: 'informationStaticBase', source: informationStaticBaseSource },
            { name: 'informationStaticCollision', source: informationStaticCollisionSource },
            { name: 'informationStaticFloorShadow', source: informationStaticFloorShadowSource, type: 'texture' },

            { name: 'informationContactTwitterLabel', source: informationContactTwitterLabelSource, type: 'texture' },
            { name: 'informationContactGithubLabel', source: informationContactGithubLabelSource, type: 'texture' },
            { name: 'informationContactLinkedinLabel', source: informationContactLinkedinLabelSource, type: 'texture' },
            { name: 'informationContactMailLabel', source: informationContactMailLabelSource, type: 'texture' },

            { name: 'informationActivities', source: informationActivitiesSource, type: 'texture' },

            // Playground
            { name: 'playgroundStaticBase', source: playgroundStaticBaseSource },
            { name: 'playgroundStaticCollision', source: playgroundStaticCollisionSource },
            { name: 'playgroundStaticFloorShadow', source: playgroundStaticFloorShadowSource, type: 'texture' },

            // Brick
            { name: 'brickBase', source: brickBaseSource },
            { name: 'brickCollision', source: brickCollisionSource },

            // Bownling ball
            { name: 'bowlingBallBase', source: bowlingBallBaseSource },
            { name: 'bowlingBallCollision', source: bowlingBallCollisionSource },

            // Rocket ball
            { name: 'rocketBase', source: rocketBaseSource },
            { name: 'rocketCollision', source: rocketCollisionSource },

            // Airdrop
            { name: 'airdropBase', source: airdropBaseSource },
            { name: 'airdropCollision', source: airdropCollisionSource },

             // Battery charger
             { name: 'batteryChargerBase', source: batteryChargerBaseSource },
             { name: 'batteryChargerBaseCollision', source: batteryChargerCollisionSource },

            // Bownling ball
            { name: 'bowlingPinBase', source: bowlingPinBaseSource },
            { name: 'bowlingPinCollision', source: bowlingPinCollisionSource },

            // Areas
            { name: 'areaKeyEnter', source: areaKeyEnterSource, type: 'texture' },
            { name: 'areaEnter', source: areaEnterSource, type: 'texture' },
            { name: 'areaOpen', source: areaOpenSource, type: 'texture' },
            { name: 'areaReset', source: areaResetSource, type: 'texture' },
            { name: 'areaQuestionMark', source: areaQuestionMarkSource, type: 'texture' },

            // Tiles
            { name: 'tilesABase', source: tilesABaseSource },
            { name: 'tilesACollision', source: tilesACollisionSource },

            { name: 'tilesBBase', source: tilesBBaseSource },
            { name: 'tilesBCollision', source: tilesBCollisionSource },

            { name: 'tilesCBase', source: tilesCBaseSource },
            { name: 'tilesCCollision', source: tilesCCollisionSource },

            { name: 'tilesDBase', source: tilesDBaseSource },
            { name: 'tilesDCollision', source: tilesDCollisionSource },

            { name: 'tilesEBase', source: tilesEBaseSource },
            { name: 'tilesECollision', source: tilesECollisionSource },

        ])

        this.loader.on('fileEnd', (_resource, _data) =>
            {
                this.items[_resource.name] = _data
            
                // Texture
                if(_resource.type === 'texture')
                {
                    const texture = new THREE.Texture(_data)
                    texture.needsUpdate = true
            
                    this.items[`${_resource.name}Texture`] = texture
                }
            
                // Video
                if(_resource.type === 'video')
                {
                    const videoTexture = new THREE.VideoTexture(_data)
                    videoTexture.needsUpdate = true
            
                    this.items[`${_resource.name}VideoTexture`] = videoTexture
                }
            
                // Trigger progress
                this.trigger('progress', [this.loader.loaded / this.loader.toLoad])
            })

        this.loader.on('end', () =>
        {
            // Trigger ready
            this.trigger('ready')
        })
    }
}
