import dotenv from 'dotenv';
import { Web3Modal } from '@web3modal/standalone'; // WalletConnect v2 modal
import { Web3Wallet } from '@walletconnect/web3wallet'; // WalletConnect v2 wallet
import { Core } from '@walletconnect/core';
import { WalletKit } from '@reown/walletkit'
import { ethers } from 'ethers';
import './style/main.css';
import Application from './javascript/Application.js';
import { buildApprovedNamespaces, getSdkError } from '@walletconnect/utils';

dotenv.config();

// WalletConnect v2 Project ID
const WALLETCONNECT_PROJECT_ID = process.env.WALLETCONNECT_PROJECT_ID;

let walletKit;
let web3Modal;
let walletConnectProvider;

// Initialize WalletKit
async function initializeWalletKit() {
    try {
        const core = new Core({
            projectId: WALLETCONNECT_PROJECT_ID,
        });

        walletKit = await WalletKit.init({
            core,
            metadata: {
                name: 'Krashbox',
                description: 'Crash, Collect & Cash-out',
                url: 'https://krashbox.world',
                icons: [],
            },
        });

        console.log('WalletKit initialized successfully:', walletKit);

    } catch (error) {
        console.error('Error initializing WalletKit:', error);
    }
}

// Initialize Web3Modal
async function initializeWeb3Modal() {
    try {
        web3Modal = new Web3Modal({
            projectId: WALLETCONNECT_PROJECT_ID, // Your project ID
            themeMode: 'dark',  // Set the theme for the modal
        });

        console.log('Web3Modal initialized successfully:', web3Modal);
    } catch (error) {
        console.error('Error initializing Web3Modal:', error);
    }
}

async function initializeWalletConnectProvider() {
    try {
        // Initialize WalletConnect Core and WalletKit
        const core = new Core({
            projectId: WALLETCONNECT_PROJECT_ID,
        });

        console.log('Core initialized:', core);

        walletKit = await WalletKit.init({
            core,
            metadata: {
                name: 'Krashbox',
                description: 'Crash, Collect & Cash-out',
                url: 'https://krashbox.world', // Use the verified domain here
                icons: [],
            },
        });

        console.log('WalletKit initialized successfully:', walletKit);

        // Handle session proposals
        walletKit.on('session_proposal', async (proposal) => {
            console.log('Session proposal received:', proposal);

            const approvedNamespaces = buildApprovedNamespaces({
                proposal: proposal.params,
                supportedNamespaces: {
                    eip155: {
                        chains: ['eip155:1', 'eip155:137'], // Ethereum Mainnet and Polygon
                        methods: ['eth_sendTransaction', 'personal_sign'],
                        events: ['accountsChanged', 'chainChanged'],
                        accounts: [
                            'eip155:1:0xab16a96d359ec26a11e2c2b3d8f8b8942d5bfcdb',
                        ],
                    },
                },
            });

            const session = await walletKit.approveSession({
                id: proposal.id,
                namespaces: approvedNamespaces,
            });

            console.log('Session approved:', session);
        });

        // Retrieve a valid URI for manual pairing
        console.log('Waiting for session proposal...');

        // Ensure that a valid WalletConnect URI is passed
        const validPairingUri = await getValidPairingUri(); // You need to implement this function
        await walletKit.pair({ uri: validPairingUri });

        console.log('Pairing initiated with URI:', validPairingUri);

    } catch (error) {
        console.error('Error initializing WalletConnect provider:', error);
    }
}

async function getValidPairingUri() {
    // Here, you should retrieve or generate a valid WalletConnect pairing URI
    // Replace with a real URI for testing
    return "wc:<valid_topic>@2?relay-protocol=https%3A%2F%2Fbridge.walletconnect.org&key=<generated_key>";
}


function injectCustomStyles() {
    const style = document.createElement('style');
    style.textContent = `
            w3m-modal {
            position: absolute;
            z-index: 99999 !important;
            visibility: visible !important;
            opacity: 1 !important;
            display: block !important;
        }
        .w3m-overlay {
            position: absolute;
            z-index: 99999 !important;
            visibility: visible !important;
            opacity: 1 !important;
            display: block !important;
        }
        .w3m-modal {
            position: absolute;
            z-index: 99999 !important;
            visibility: visible !important;
            opacity: 1 !important;
            display: block !important;
        }
        .w3m-qr-code {
            position: absolute;
            z-index: 99999 !important;
            visibility: visible !important;
            opacity: 1 !important;
            display: block !important;
    }
    `;
    document.head.appendChild(style);
    console.log('Custom styles injected.');
}

// function injectCustomStyles() {
//     const style = document.createElement('style');
//     style.textContent = `
//         .walletconnect-modal {
//             position: absolute;
//             z-index: 99999 !important;
//             visibility: visible !important;
//             opacity: 1 !important;
//             display: block !important;
//         }
//         .walletconnect-overlay {
//             position: absolute;
//             z-index: 99999 !important;
//             visibility: visible !important;
//             opacity: 1 !important;
//             display: block !important;
//         }
//     `;
//     document.head.appendChild(style);
//     console.log('Custom styles injected.');
// }

// Connect Wallet using Web3Modal
async function connectWallet() {
    try {
        console.log('Connecting wallet...');

        if (!walletKit || !web3Modal) {
            console.error('WalletKit or Web3Modal is not initialized');
            return;
        }

        console.log('Opening Web3Modal...');

        // Open the Web3Modal for wallet selection and await the provider
        const provider = await web3Modal.openModal(); // Wait for the wallet modal to return a provider

        if (provider) {
            console.log('Provider received from Web3Modal:', provider);
            await handleSessionProposal(provider); // Pass the provider for handling session
        } else {
            console.log('No provider was returned.');
            console.log('Falling back to manual WalletConnect initialization...');
            await initializeWalletConnectProvider();
        }

    } catch (error) {
        console.error('Error connecting wallet:', error);
        alert('Failed to connect wallet. Please try again.');
    }
}

// Handle session proposals from WalletKit
async function handleSessionProposal(provider) {
    // Listen for session proposals and approve them
    walletKit.on('session_proposal', async (proposal) => {
        console.log('Session proposal received:', proposal);

        const approvedNamespaces = buildApprovedNamespaces({
            proposal: proposal.params,
            supportedNamespaces: {
                eip155: {
                    chains: ['eip155:1', 'eip155:137'],
                    methods: ['eth_sendTransaction', 'personal_sign'],
                    events: ['accountsChanged', 'chainChanged'],
                    accounts: [
                        'eip155:1:0xab16a96d359ec26a11e2c2b3d8f8b8942d5bfcdb',
                    ]
                }
            }
        });

        const session = await walletKit.approveSession({
            id: proposal.id,
            namespaces: approvedNamespaces,
        });

        console.log('Session approved:', session);

        const web3Provider = new ethers.providers.Web3Provider(walletKit.core.client.getProvider());
        const signer = web3Provider.getSigner();
        const accounts = await signer.getAddress();
        console.log('Connected account:', accounts);

        web3Provider.on('accountsChanged', (accounts) => {
            console.log('Account changed:', accounts[0]);
            localStorage.setItem('playerId', accounts[0]);
        });

        web3Provider.on('disconnect', () => {
            console.log('Disconnected');
            signOut();
        });

        localStorage.setItem('playerId', accounts);
        return accounts;
    });
}

async function signOut() {
    try {
        const playerId = localStorage.getItem('playerId');
        if (!playerId) {
            throw new Error('No player ID found. Please sign in first.');
        }

        // Clear the playerId from localStorage
        localStorage.removeItem('playerId');
        console.log('Player ID removed from localStorage');

        // Reload the page
        window.location.reload();
    } catch (error) {
        console.error('Error signing out:', error);
        alert('Sign-out failed. Please try again.');
    }
}

async function initializeApplication() {
    const playerId = await connectWallet();
    if (playerId) {
        const connectWalletDiv = document.getElementById('connectWalletDiv');
        if (connectWalletDiv) {
            document.body.removeChild(connectWalletDiv);
        }

        // Initialize the application
        window.application = new Application({
            $canvas: document.querySelector('.js-canvas'),
            useComposer: true,
            playerId: playerId,
        });
        console.log('Application initialized with player ID:', playerId);
    } else {
        const button = document.getElementById('connectWalletButton');
        if (button) {
            button.disabled = false;
            button.innerHTML = 'Connect Wallet';
        }
    }
}

function createConnectWalletScreen() {
    const connectWalletDiv = document.createElement('div');
    connectWalletDiv.id = 'connectWalletDiv';
    connectWalletDiv.style.position = 'absolute';
    connectWalletDiv.style.top = '50%';
    connectWalletDiv.style.left = '50%';
    connectWalletDiv.style.transform = 'translate(-50%, -50%)';
    connectWalletDiv.style.padding = '20px';
    connectWalletDiv.style.display = 'flex';
    connectWalletDiv.style.justifyContent = 'center';
    connectWalletDiv.style.alignItems = 'center';
    connectWalletDiv.style.width = '100%';
    connectWalletDiv.style.height = '100%';
    connectWalletDiv.style.color = '#ffffff';
    connectWalletDiv.style.fontFamily = 'Orbitron';
    connectWalletDiv.style.zIndex = '1000';

    const button = document.createElement('button');
    button.id = 'connectWalletButton';
    button.innerHTML = 'Connect Wallet';
    button.style.fontSize = '15px';
    button.style.padding = '20px 20px';
    button.style.cursor = 'pointer';
    button.style.fontFamily = 'Orbitron';

    connectWalletDiv.appendChild(button);
    document.body.appendChild(connectWalletDiv);

    button.onclick = async () => {
        console.log('Connect Wallet button clicked');
        button.disabled = true;
        button.innerHTML = 'Connecting...';

        try {
            await initializeApplication();
        } catch (error) {
            console.error('Error initializing application:', error);
            button.disabled = false;
            button.innerHTML = 'Connect Wallet';
        }
    };
}

(async () => {
    console.log('Initializing WalletConnect...');
    await initializeWalletKit(); // Correctly assign to global walletKit
    await initializeWeb3Modal(); // Initialize Web3Modal
    createConnectWalletScreen();
    injectCustomStyles();
})();

const signOutButton = document.getElementById('signOutButton');
if (signOutButton) {
    signOutButton.addEventListener('click', signOut);
}
